/* Card CSS */

.card {
    margin-bottom: 1.875rem;
    background-color: #fff;
    transition: all .5s ease-in-out;
    position: relative;
    border: 1px solid rgba(170, 170, 170, 0.3);
    border-radius: 12px;
    box-shadow: 0px 5px 5px 0px rgba(82, 63, 105, 0.05);
    height: calc(100% - 30px);
    /* overflow: hidden; */
}



.custom-input {
    background-color: #fff;
    border: 1px solid #ced4da;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    color: #495057;
    cursor: pointer;
    width: 100%;
    text-align: left;
}

.custom-input:focus {
    outline: none;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.react-datepicker-popper {
    z-index: 9;
    line-height: 0;
}



.rsw-toolbar {
    align-items: center;
    background-color: #f5f5f5;
    border-bottom: 1px solid #ddd;
    display: flex;
    width: 98% !important;
}

.upper-case {
    text-transform: uppercase;
}